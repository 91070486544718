import { Button } from '@mui/material';

const DownloadButton = () => {
  return (
    <Button 
      variant="contained" 
      size="large" 
      sx={{ 
        position: 'relative', 
        background: '#44ac44', 
        color: '#fff',
        fontSize: '22px',
        px: 6, 
        py: 2.5,
        borderRadius: '12px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        overflow: 'hidden',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:before': { 
          content: '""',
          position: 'absolute',
          top: '0',
          left: '-100%',
          width: '25%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.1)', 
          transform: 'skewX(-30deg)',  
          animation: 'moveReflet 3s ease-in-out infinite',
        },
        '&:hover': {  
          transform: 'scale(1.05)', 
          boxShadow: '0 6px 15px rgba(0, 0, 0, 0.2)',
        },
        '@keyframes moveReflet': {
          '0%': {
            left: '-100%'
          },
          '50%': {
            left: '150%'
          },
          '100%': {
            left: '150%'
          }
        }
      }}
    >
      🎉 Télécharger l'App 🚀
    </Button>
  );
};

export default DownloadButton;
