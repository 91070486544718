import { Box, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import HeroButton from './HeroButton';
import HeroText from './HeroText'; // Import du composant HeroText
import HeroTitle from './HeroTitle';
const HeroSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsVisible(scrollTop > 200); // Affiche les textes au scroll
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Box
      sx={{
        backgroundImage: `url('/images/fond_hero.webp')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          py: 6,
          pt: 12,
          textAlign: 'center',
        }}
      >
        <HeroTitle isVisible={isVisible} />

        <HeroText isVisible={isVisible} />
        
        <HeroButton isVisible={isVisible} />

      </Container>
    </Box>
  );
};

export default HeroSection;
