import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Button, Container, Drawer, IconButton, List, ListItem, ListItemText, Stack, Toolbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrollDirection, setScrollDirection] = useState('up');
  const [lastScrollY, setLastScrollY] = useState(0);

  // Toggle the drawer for mobile navigation
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  // Hide Navbar on scroll down, show on scroll up (on all screens)
  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY && currentScrollY > 100) {
      setScrollDirection('down');
    } else if (currentScrollY < lastScrollY || currentScrollY <= 100) {
      setScrollDirection('up');
    }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    // Add event listener for scroll
    window.addEventListener('scroll', handleScroll);

    return () => {
      // Cleanup event listener when component unmounts
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          bgcolor: '#2E7D32',
          padding: 1,
          transition: 'top 0.3s',
          top: scrollDirection === 'down' ? '-100px' : '0', // Hide on scroll down for all screens
        }}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            {/* Menu Icon Button (Only for Mobile) */}
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2, display: { xs: 'block', md: 'none' } }} // Show only on small screens
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>

            {/* Logo Image */}
            <Box component={Link} to="/" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
              <img
                src="/images/IndiceFossileLogo.png"
                alt="Indice Fossile Logo"
                style={{ height: 55, marginRight: 10 }}
              />
            </Box>

            {/* Desktop Navigation Links */}
            <Stack direction="row" spacing={3} sx={{ ml: 'auto', display: { xs: 'none', md: 'flex' } }}>
              <Button color="inherit" component={Link} to="/" sx={{ fontSize: '1rem', color: '#ffffff', '&:hover': { textDecoration: 'underline', color: '#AED581' } }}>
                Accueil
              </Button>
              <Button color="inherit" component={Link} to="/about" sx={{ fontSize: '1rem', color: '#ffffff', '&:hover': { textDecoration: 'underline', color: '#AED581' } }}>
                Notre Démarche
              </Button>
              <Button color="inherit" component={Link} to="/services" sx={{ fontSize: '1rem', color: '#ffffff', '&:hover': { textDecoration: 'underline', color: '#AED581' } }}>
                Services
              </Button>
              <Button color="inherit" component={Link} to="/contact" sx={{ fontSize: '1rem', color: '#ffffff', '&:hover': { textDecoration: 'underline', color: '#AED581' } }}>
                Contact
              </Button>
              <Button color="inherit" component={Link} to="/donations" sx={{ fontSize: '1rem', color: '#ffffff', '&:hover': { textDecoration: 'underline', color: '#AED581' } }}>
                Adhésion / Dons
              </Button>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Drawer for Mobile Navigation */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {['Accueil', 'Notre Démarche', 'Services', 'Contact', 'Adhésion / Dons'].map((text, index) => (
              <ListItem button key={text} component={Link} to={index === 0 ? '/' : `/${text.toLowerCase().replace(' / ', '').replace(' ', '-')}`}>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default Navbar;
