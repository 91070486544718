import { Button } from '@mui/material';

const HeroButton = () => {
  return (
    <Button
      variant="contained"
      size="large"
      sx={{
        bgcolor: '#44ac44',
        color: '#fff',
        fontWeight: 'bold',
        px: 4,
        py: 2,
        fontSize: '18px',
        borderRadius: '8px',
        textTransform: 'uppercase',
        animation: 'fadeIn 2s ease-out',
        transition: 'transform 0.3s ease',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: '0 6px 15px rgba(0, 0, 0, 0.2)',
          bgcolor: '#256b28',
        },
      }}
      href="#process-section"
      endIcon={<span>🚀</span>}
    >
      Découvre comment ça marche
    </Button>
  );
};

export default HeroButton;
