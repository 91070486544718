import React from 'react';

const AdhesionForm = () => {
  return (
    <div>
      <h2>Formulaire d'adhésion</h2>
      <p>Cette page affichera le formulaire d'adhésion.</p>
    </div>
  );
};

export default AdhesionForm;

