import { Box, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

const HeroText = () => {
  const [isVisible, setIsVisible] = useState([false, false, false]);
  const textRefs = useRef([]);

  // Utilise l'API IntersectionObserver pour détecter quand les éléments sont dans le viewport
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = parseInt(entry.target.dataset.index, 10);
          if (entry.isIntersecting) {
            setIsVisible((prevState) => {
              const newState = [...prevState];
              newState[index] = true; // Marque la phrase comme visible
              return newState;
            });
          }
        });
      },
      {
        threshold: 1, // 30% de la box visible
      }
    );

    // Observe chaque texte
    textRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    // Nettoyage lorsque le composant est démonté
    return () => {
      textRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        maxWidth: '750px',
        mx: 'auto',
        pt: 0,
        pb: 4,
      }}
    >
      {/* Première ligne avec smiley et texte */}
      <Box
        ref={(el) => (textRefs.current[0] = el)} // Associe chaque texte à une ref pour observer
        data-index="0"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 2,
          fontSize: '30px',
          gap: 1,
          opacity: isVisible[0] ? 1 : 0,
          transform: isVisible[0] ? 'translateY(0)' : 'translateY(20px)',
          transition: 'opacity 0.5s ease-out, transform 0.5s ease-out',
        }}
      >
        😕
        <Typography
          variant="h6"
          sx={{
            color: '#4F4F4F',
            fontSize: { xs: '20px', sm: '22px', md: '24px' },
            fontFamily: 'Poppins, sans-serif',
            letterSpacing: '1px',
            fontWeight: 'bold',
            textShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)',
            textAlign: 'left',
          }}
        >
          À quoi bon faire des efforts si les vrais responsables continuent à ignorer l'avenir ?
        </Typography>
      </Box>

      {/* Deuxième ligne avec smiley et texte */}
      <Box
        ref={(el) => (textRefs.current[1] = el)}
        data-index="1"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 3,
          fontSize: '30px',
          gap: 1,
          opacity: isVisible[1] ? 1 : 0,
          transform: isVisible[1] ? 'translateY(0)' : 'translateY(20px)',
          transition: 'opacity 0.5s ease-out, transform 0.5s ease-out',
        }}
      >
        🤝
        <Typography
          variant="h6"
          sx={{
            color: '#4F4F4F',
            fontSize: { xs: '20px', sm: '22px', md: '24px' },
            fontFamily: 'Poppins, sans-serif',
            letterSpacing: '1px',
            fontWeight: 'bold',
            textShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)',
            textAlign: 'left',
          }}
        >
          Mais ensemble, en choisissant mieux, nous pouvons forcer les entreprises à changer.
        </Typography>
      </Box>

      {/* Troisième ligne avec smiley et texte */}
      <Box
        ref={(el) => (textRefs.current[2] = el)}
        data-index="2"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 4,
          fontSize: '30px',
          gap: 1,
          opacity: isVisible[2] ? 1 : 0,
          transform: isVisible[2] ? 'translateY(0)' : 'translateY(20px)',
          transition: 'opacity 0.5s ease-out, transform 0.5s ease-out',
        }}
      >
        🌱
        <Typography
          variant="h6"
          sx={{
            color: '#4F4F4F',
            fontSize: { xs: '20px', sm: '22px', md: '24px' },
            fontFamily: 'Poppins, sans-serif',
            letterSpacing: '1px',
            fontWeight: 'bold',
            textShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)',
            textAlign: 'left',
          }}
        >
          C’est simple, ça a de l'impact, et ça commence maintenant !
        </Typography>
      </Box>
    </Box>
  );
};

export default HeroText;
