import { ArrowDownward, QrCode, ReportProblem, Store } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

const ProcessSection = () => {
  const [isVisible, setIsVisible] = useState([false, false, false, false]);

  // Observer pour activer les animations quand les cards entrent en vue
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            const cardIndex = entry.target.dataset.index; // Récupère l'index de la carte à partir de l'attribut data-index
            setIsVisible((prev) => {
              const updated = [...prev];
              updated[cardIndex] = true; // Met à jour la carte spécifique
              return updated;
            });
          }
        });
      },
      {
        threshold: 0.2, // Le seuil est à 20% visible pour déclencher l'animation
      }
    );

    const elements = document.querySelectorAll('.process-card');
    elements.forEach((el) => observer.observe(el));

    return () => observer.disconnect();
  }, []);

  return (
    <Box id="process-section" sx={{ textAlign: 'center', py: 6, bgcolor: '#2E7D32', minHeight: '100vh' }}>
      <Typography 
        variant="h3" 
        sx={{ 
          fontWeight: '700',  
          color: '#fff', 
          mb: 4, 
          textAlign: 'center',
          quotes: '"“" "”" "‘" "’"',  
        }}
      >
        <Box 
          component="span" 
          sx={{ 
            fontFamily: 'Nefelibata, cursive',  
            fontSize: '0.8em', 
            color: '#e0f7ea', 
            fontStyle: 'italic',
            fontWeight: 'normal',
          }}
        >
          “Une app pour mieux consommer ET avoir un impact ?”
        </Box>
        <Box 
          component="span" 
          sx={{ 
            fontFamily: 'Poppins, sans-serif',  
            fontWeight: 'bold', 
            color: '#A7D7A7',
            fontSize: '1.2em', 
            ml: 2 
          }}
        >
          <br />  
          Exactement !
        </Box>
      </Typography>

      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' },  
          justifyContent: 'center', 
          alignItems: 'center', 
          gap: { xs: 6, md: 4 },  
          px: { xs: 2, md: 4 },  
          width: { xs: '90%', sm: '95%' },
          mx: 'auto' 
        }}
      >
        {/* Étape 1 : Scanner le produit */}
        <Box 
          className="process-card"
          data-index="0"
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            width: { xs: '100%', md: '250px' }, 
            position: 'relative', 
            p: 3, 
            bgcolor: '#A7D7A7',  
            borderRadius: 2, 
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',  
            transform: isVisible[0] ? 'scale(1)' : 'scale(0.7)',
            opacity: isVisible[0] ? 1 : 0,
            transition: 'all 0.5s ease-in-out',
            '&:hover': {
              transform: 'scale(1.05)',  
              boxShadow: '0 8px 20px rgba(0, 0, 0, 0.3)',  
            }
          }}
        >
          <Box sx={{
            position: 'absolute',
            top: '5px',
            left: '6px',
            bgcolor: '#44ac44',
            color: '#fff',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '18px',
            fontWeight: 'bold',
          }}>
            1
          </Box>
          <QrCode sx={{ fontSize: 50, color: '#2E7D32', mb: 2 }} />
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#2E7D32', mb: 1 }}>
            Je scanne ma <span style={{ fontStyle: 'italic' }}>Pâte Choco</span> 🍫
          </Typography>
          <Typography variant="body1" sx={{ color: '#333' }}>
            Avec l'application, je vérifie l'impact de ma pâte à tartiner préférée !
          </Typography>
        </Box>

        {/* Étape 2 : Le produit est mal noté */}
        <Box 
          className="process-card"
          data-index="1"
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            width: { xs: '100%', md: '250px' }, 
            position: 'relative', 
            p: 3, 
            bgcolor: '#A7D7A7',  
            borderRadius: 2, 
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',  
            transform: isVisible[1] ? 'scale(1)' : 'scale(0.7)',
            opacity: isVisible[1] ? 1 : 0,
            transition: 'all 0.5s ease-in-out',
            '&:hover': {
              transform: 'scale(1.05)',  
              boxShadow: '0 8px 20px rgba(0, 0, 0, 0.3)',  
            }
          }}
        >
          <Box sx={{
            position: 'absolute',
            top: '5px',
            left: '6px',
            bgcolor: '#ff6347',
            color: '#fff',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '18px',
            fontWeight: 'bold',
          }}>
            2
          </Box>
          <ReportProblem sx={{ fontSize: 50, color: 'red', mb: 2 }} />
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#ff6347', mb: 1 }}>
            Oh non ! Mauvaise note 😟
          </Typography>
          <Typography variant="body1" sx={{ color: '#333' }}>
            Wahh, cette <span style={{ fontStyle: 'italic' }}>Pâte Choco</span> vient de l'autre bout du monde 😒 et est fabriquée dans des conditions désastreuses. Ça va pas se passer comme ça !
          </Typography>
        </Box>

        {/* Étape 3 : Je choisis mieux */}
        <Box 
          className="process-card"
          data-index="2"
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            width: { xs: '100%', md: '250px' }, 
            position: 'relative', 
            p: 3, 
            bgcolor: '#A7D7A7',  
            borderRadius: 2, 
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',  
            transform: isVisible[2] ? 'scale(1)' : 'scale(0.7)',
            opacity: isVisible[2] ? 1 : 0,
            transition: 'all 0.5s ease-in-out',
            '&:hover': {
              transform: 'scale(1.05)',  
              boxShadow: '0 8px 20px rgba(0, 0, 0, 0.3)',  
            }
          }}
        >
          <Box sx={{
            position: 'absolute',
            top: '5px',
            left: '6px',
            bgcolor: '#44ac44',
            color: '#fff',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '18px',
            fontWeight: 'bold',
          }}>
            3
          </Box>
          <Store sx={{ fontSize: 50, color: '#2E7D32', mb: 2 }} />
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#2E7D32', mb: 1 }}>
            Je choisis mieux 🍃
          </Typography>
          <Typography variant="body1" sx={{ color: '#333' }}>
            L'appli me recommande une pâte locale <span style={{ fontStyle: 'italic' }}>Pâte Verte</span>, produite à côté de chez moi avec des ingrédients durables. 😋
          </Typography>
        </Box>

        {/* Étape 4 : L'entreprise change */}
        <Box 
          className="process-card"
          data-index="3"
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            width: { xs: '100%', md: '250px' }, 
            position: 'relative', 
            p: 3, 
            bgcolor: '#A7D7A7',  
            borderRadius: 2, 
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',  
            transform: isVisible[3] ? 'scale(1)' : 'scale(0.7)',
            opacity: isVisible[3] ? 1 : 0,
            transition: 'all 0.5s ease-in-out',
            '&:hover': {
              transform: 'scale(1.05)',  
              boxShadow: '0 8px 20px rgba(0, 0, 0, 0.3)',  
            }
          }}
        >
          <Box sx={{
            position: 'absolute',
            top: '5px',
            left: '6px',
            bgcolor: '#44ac44',
            color: '#fff',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '18px',
            fontWeight: 'bold',
          }}>
            4
          </Box>
          <ReportProblem sx={{ fontSize: 50, color: '#2E7D32', mb: 2 }} />
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#2E7D32', mb: 1 }}>
            L'entreprise change ses pratiques 💡
          </Typography>
          <Typography variant="body1" sx={{ color: '#333' }}>
            Grâce à ma décision, <span style={{ fontStyle: 'italic' }}>Pâte Choco</span> se met à réviser ses méthodes de production 🌱. La prochaine version sera enfin plus éthique !
          </Typography>
        </Box>
      </Box>

      {/* Bouton pour indiquer que l'app est en dessous */}
      <Button 
        variant="contained" 
        size="large" 
        sx={{ 
          mt: 4, 
          bgcolor: '#44ac44', 
          color: '#fff',
          fontWeight: 'bold',
          px: 4, 
          py: 2,
          fontSize: '18px',
          borderRadius: '8px',
          textTransform: 'uppercase',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0 6px 15px rgba(0, 0, 0, 0.2)',
            bgcolor: '#256b28',
          },
        }}
        href="#download-app" 
        endIcon={<ArrowDownward />}  
      >
        Téléchargez l'application
      </Button>
    </Box>
  );
};

export default ProcessSection;
