import { Box, Typography } from '@mui/material';

const HeroTitle = () => {
  return (
    <Typography
      variant="h2"
      sx={{
        fontWeight: 'bold',
        mb: 4,
        color: '#2E7D32',
        position: 'relative',
        fontFamily: 'Anton, sans-serif',
        letterSpacing: '2px',
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
        animation: 'fadeIn 1.5s ease-out',
      }}
    >
      Agis pour un{' '}
      <Box
        component="span"
        sx={{
          fontFamily: 'Nefelibata',
          color: '#44ac44',
          fontWeight: 'normal',
          fontSize: '70px',
          display: 'inline-block',
          position: 'relative',
          marginLeft: 2,
          letterSpacing: '0px',
          textShadow: '3px 3px 6px rgba(0, 0, 0, 0.3)',
        }}
      >
        futur meilleur
      </Box>
      🌍
    </Typography>
  );
};

export default HeroTitle;
