import { Container, Typography } from '@mui/material';

const AboutUsSection = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4, color: '#2E7D32' }}>
        Notre Démarche
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        {/* Explication de la démarche */}
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      </Typography>

      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4, color: '#2E7D32' }}>
        Constat
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        {/* Description du constat */}
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      </Typography>

      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4, color: '#2E7D32' }}>
        Valeurs / Objectifs
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        {/* Explication des valeurs et objectifs */}
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      </Typography>

      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4, color: '#2E7D32' }}>
        Notre équipe
      </Typography>
      <Typography variant="body1">
        {/* Présentation de l'équipe */}
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      </Typography>
    </Container>
  );
};

export default AboutUsSection;
