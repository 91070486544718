import { Box, CardMedia, Container, Typography } from '@mui/material';
import DownloadButton from './DownloadButton';

const DownloadAppSection = () => {
  return (
    <Container 
        id="download-app"
        maxWidth="lg"
        sx={{ 
          mx: 'auto',
          minHeight: '100vh',
          py: { xs: 2, md: 8 },
        }}
        >
        <Container 
        maxWidth="lg" 
        sx={{ 
            py: 4, 
            background: 'linear-gradient(135deg, #f0f8f0 0%, #d0e8d0 100%)',
            borderRadius: 8,
            boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.3)',
            p: 2,
            maxWidth: '900px',
            mx: 'auto'
        }}
        >
        <Box 
            sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'center', 
            justifyContent: 'center', 
            gap: { xs: 3, md: 7 },
            py: 2,  
            maxWidth: '900px',
            mx: 'auto',  
            animation: 'fadeInUp 1s ease-out',
            }}
        >
            {/* Partie gauche */}
            <Box 
            sx={{ 
                flex: { xs: 'unset', md: '1' }, 
                maxWidth: { xs: '100%', md: '55%' },
                textAlign: { xs: 'center', md: 'left' },
                animation: 'fadeInLeft 1s ease',
            }}
            >
            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4, color: '#2E7D32', fontSize: { xs: '1.5rem', md: '2rem' } }}>
                Télécharge l'<Box component="span" sx={{ fontFamily: 'Nefelibata', color: '#44ac44', fontWeight: 'normal', fontSize: { xs: '2.5rem', md: '3.5rem' } }}>App</Box> et Rejoins le Mouvement 📲
            </Typography>
            <Typography variant="h6" sx={{ color: 'text.secondary', mb: 4, fontSize: { xs: '1rem', md: '1.25rem' } }}>
                Installe l'application 
                <Box component="img" src="/images/IndiceFossileLogo.png" alt="logo de l'app" 
                sx={{ height: '1.7em', display: 'inline', mx: 0.1, verticalAlign: 'middle', position: 'relative', top: '-2px' }} />
                et commence dès aujourd'hui à <Box component="span" sx={{ color: '#256b28', fontWeight: 'bold' }}>réduire ton empreinte carbone</Box> tout en découvrant des produits qui te correspondent.
            </Typography>
            </Box>

            {/* Partie droite */}
            <Box 
            sx={{ 
                flex: { xs: 'unset', md: '0 1 auto' }, 
                maxWidth: { xs: '100%', md: '40%' },
                display: 'flex', 
                flexDirection: { xs: 'row', md: 'column' }, // Changer la direction sur mobile et desktop
                alignItems: 'center',
                justifyContent: 'center',
                gap: 4,
                animation: 'fadeInRight 1s ease',
                textAlign: 'center', 
            }}
            >
                <CardMedia
                    component="img"
                    image="/images/IndiceFossileLogoSimpleApp.png"
                    alt="Logo Indice Fossile"
                    sx={{ 
                    maxWidth: { xs: 150, md: 185 }, 
                    mb: 1, 
                    filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.3))',
                    transition: 'transform 0.3s ease',
                    '&:hover': { transform: 'scale(1.05)' }
                    }}  
                />

                <Box sx={{ display: 'flex', flexDirection:  'column' , alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                    <Typography variant="h6" sx={{ color: 'text.secondary', textAlign: 'center' }}>
                        Disponible sur :
                    </Typography>

                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2 }}>
                        <CardMedia
                        component="img"
                        image="/images/AppStore.png"
                        alt="Télécharger sur l'App Store"
                        sx={{ 
                            height: { xs: 30, md: 30 }, 
                            transition: 'transform 0.3s ease', 
                            '&:hover': { transform: 'scale(1.1)', cursor: 'pointer' }
                        }}  
                        />
                        <CardMedia
                        component="img"
                        image="/images/PlayStore.png"
                        alt="Télécharger sur Google Play"
                        sx={{ 
                            height: { xs: 30, md: 30 }, 
                            transition: 'transform 0.3s ease', 
                            '&:hover': { transform: 'scale(1.1)', cursor: 'pointer' }
                        }}  
                        />  
                    </Box>
                </Box>
            </Box>
        </Box>

        <Box sx={{ textAlign: 'center', mt: 4 }}>
            <DownloadButton />
            <Typography variant="body2" sx={{ color: '#777', textAlign: 'center', mt: 1 }}>
            Plus de 10 000 téléchargements !
            </Typography>
        </Box>
        </Container>
    </Container>
  );
};

export default DownloadAppSection;
