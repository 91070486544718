import { Container, Typography } from '@mui/material';

const NewsSection = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4, color: '#2E7D32' }}>
        Actualités
      </Typography>
      <Typography variant="body1">
        {/* Informations sur les actualités */}
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      </Typography>
    </Container>
  );
};

export default NewsSection;
