import { Box } from '@mui/material';
import React from 'react';
import AboutUsSection from './AboutUsSection';
import ApplicationSection from './ApplicationSection';
import DownloadAppSection from './DownloadAppSection';
import Footer from './Footer';
import HeroSection from './HeroSection';
import NewsSection from './NewsSection';
import ProcessSection from './ProcessSection';

const Home = () => {
  return (
    <Box sx={{ bgcolor: '#f5f8f5', minHeight: '100vh' }}>

      {/* Intro + explicataion rapide de la vision */}
      <HeroSection />
      {/* Section Processus */}
      <ProcessSection />
      {/* Section de téléchargement de l'application */}
      <DownloadAppSection />
      {/* Section Application */}
      <ApplicationSection />
      {/* Section Qui sommes-nous */}
      <AboutUsSection />
      {/* Section Actualités */}
      <NewsSection />
      {/* Bas de page */}
      <Footer />
    
    </Box>
  );
};

export default Home;
