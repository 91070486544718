import { Container, Typography } from '@mui/material';

const ApplicationSection = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4, color: '#2E7D32' }}>
        Indice Fossile, c'est quoi ?
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        {/* Ici, tu pourras ajouter l'explication sur ce qu'est l'application */}
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      </Typography>

      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4, color: '#2E7D32' }}>
        Comment ça marche ?
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        {/* Ajouter ici la section expliquant le fonctionnement de l'application */}
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      </Typography>

      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4, color: '#2E7D32' }}>
        Des Données Transparentes à votre disposition
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        {/* Explication des données transparentes */}
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      </Typography>

      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4, color: '#2E7D32' }}>
        Nos Services
      </Typography>
      <Typography variant="body1">
        {/* Description des services */}
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      </Typography>
    </Container>
  );
};

export default ApplicationSection;
