import { Box, Container, Link, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ bgcolor: '#f5f5f5', py: 4 }}>
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          {/* Contact */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Contact
            </Typography>
            <Typography variant="body1">
              {/* Détails de contact */}
              contact@indicefossile.com
            </Typography>
          </Box>

          {/* Réseaux sociaux */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Suivez-nous
            </Typography>
            <Typography variant="body1">
              {/* Liens vers les réseaux sociaux */}
              <Link href="#">Facebook</Link>
              <Link href="#">Instagram</Link>
            </Typography>
          </Box>

          {/* Adhésion / dons */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Adhésion / Dons
            </Typography>
            <Typography variant="body1">
              {/* Détails pour adhérer ou faire un don */}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Typography>
          </Box>

          {/* Mentions légales */}
          <Box>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Mentions légales
            </Typography>
            <Typography variant="body1">
              {/* Mentions légales */}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
